import React from "react";
import Menus from "../../Screens/Customer/Home/Menus/Menus";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate();
  return (
    <>
      <Menus />
      <div className="container d-flex justify-content-center mt-5 ">
        <div className="profile p-3 py-4 mt-2">
          <div className="text-center">
            <h3> My Account </h3>
            <hr className="line" />
            <div className="d-flex justify-content-center  gap-5 mt-4">
              <div className="d-flex gap-4 flex-column justify-content-end align-items-start mb-2">
                <h6 className="fonts13"> Name </h6>
                <h6 className="fonts13"> Email </h6>
                <h6 className="fonts13"> Phone </h6>
                <h6 className="fonts13"> Address </h6>
                <h6 className="fonts13"> Created At </h6>
              </div>
              <div className="d-flex gap-4 flex-column justify-content-end align-items-start ">
                <input style={{height:'25px',fontSize:'1opx'}} className="form-control  mt-2" value="Rajesh" />
                <input style={{height:'25px',fontSize:'1opx'}} className="form-control" value="rajesh@gmail.com" />
                <input style={{height:'25px',fontSize:'1opx'}} className="form-control" value="7566638323" />
              
                <input
                style={{height:'25px',fontSize:'1opx'}} 
                  className="form-control"
                  value="112, Old Mahabalipuram Rd, Phase-2, Thirumalai Nagar Annexe, Perungudi, Chennai, Tamil Nadu 600096, India"
                />
                <input style={{height:'25px',fontSize:'1opx'}}  className="form-control" value="09-02-2024" />
              </div>
            </div>
          </div>
          <button type="button" className="btn btn-primary mt-5">
            Save
          </button>
        </div>
      </div>
    </>
  );
};

export default Profile;
