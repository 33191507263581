import React, { useState } from "react";
import Menus from "../../../Home/Menus/Menus";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Heading } from "../../../../../Reusable/Headings/Heading";
import { IoIosArrowBack } from "react-icons/io";
import { DatePicker, Space } from "antd";

const AllServicesHistory = () => {
  const [selectedMonth, setSelectedMonth] = useState(null);
  const navigate = useNavigate();

  const serviceHistory = [
    {
      title: "Crawling Insects",
      date: "24-02-2024",
      status: "Completed",
      index: 1,
    },
    {
      title: "Bird Control",
      date: "27-02-2024",
      status: "Completed",
      index: 2,
    },
    {
      title: "Rodent Control",
      date: "30-01-2024",
      status: "Completed",
      index: 3,
    },
    {
      title: "SNAKE & SCORPION",
      date: "31-01-2024",
      status: "Completed",
      index: 4,
    },
  ];

  const onFinish = () => {
    navigate("/specificHistory");
  };

  const onBack = () => {
    navigate("/home");
  };

  

  const sortedHistoryByDate = serviceHistory.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateB - dateA;
  });

  const onChange = (date, dateString) => {
    if (date) {
      setSelectedMonth(date.month() + 1); // Months are zero-indexed, so add 1 to get the correct month number
    } else {
      setSelectedMonth(null);
    }
  };

  const filteredServiceHistory =
    selectedMonth !== null
      ? sortedHistoryByDate.filter((entry) => {
          // Extract month from date string and compare with selectedMonth
          const entryMonth = parseInt(entry.date.split("-")[1], 10);
          return entryMonth === selectedMonth;
        })
      : sortedHistoryByDate;

  return (
    <div className="">
      <Menus title="All Service History" />
      <div className="d-flex flex-column">
        <div className="d-flex flex-row">
          <div className="col-2">
            <IoIosArrowBack className="backArrow" onClick={onBack} />
          </div>
          <div className="col-8 d-flex justify-content-center">
            <Heading heading="All Services History" />
          </div>
        </div>
        <Space direction="vertical">
          <DatePicker onChange={onChange} picker="month" />
        </Space>
      </div>
      <div className="padding">
        {filteredServiceHistory.length === 0 ? (
          <div>No service history found</div>
        ) : (
          filteredServiceHistory.map((data) => (
            <div
              className="card d-flex flex-row serviceHistoryCard mt-3"
              key={data.index}
            >
              <div className="col-8 mt-2 p-3">
                <h6 className="serviceHistorytitle">{data.title}</h6>
                <div className="d-flex justify-content-start mt-4 mb-2">
                  <FaRegCalendarAlt className="calendarIcon" />
                  <div className="ServiceHistoryDate px-2">{data.date}</div>
                  <div className="ServiceHistoryStatus px-2">{data.status}</div>
                </div>
              </div>
              <div className="col-4 d-flex align-items-center justify-content-center">
                <button
                  type="button"
                  className="btn pestBtn"
                  onClick={onFinish}
                >
                  View
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default AllServicesHistory;
