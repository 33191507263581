import React, { useState, useEffect } from "react";
import Menus from "../../Screens/Customer/Home/Menus/Menus";
import { Heading } from "../../Reusable/Headings/Heading";
import s2 from "../../Assets/Images/s2.webp";
import Model from "../../Reusable/Model";
// import ApiService from '../../Services/TaskServices'
import { useParams, useLocation, useNavigate } from "react-router-dom";

const TaskDetails = () => {
  // const { taskId } = useParams();
  const [task, setTask] = useState({});
  const [showForm, setShowForm] = useState(false);
  const [startTask, setStartTask] = useState(false);
  const navigate = useNavigate();
  // const location = useLocation();

  // useEffect(() => {
  //   if (location.state && location.state.task) {
  //     setTask(location.state.task);
  //   } else {
  //     fetchTaskDetails();
  //   }
  // }, [location.state, taskId]);
  // const fetchTaskDetails = async () => {
  //   try {
  //     const taskDetails = await ApiService.TaskList(taskId);
  //     console.log('Task=============>',taskDetails);
  //     setTask(taskDetails);
  //   } catch (error) {
  //     console.error('Error fetching task details:', error);
  //   }
  // };

  const handleAddTeamMemberClick = () => {
    setShowForm(true);
  };

  const handleStart = () => {
    setStartTask(true);
  };

  const handleClose = () => {
    setStartTask(false);
  };

  const handleScan = () => {
    navigate("/scanqr");
  };
  return (
    <>
      <Menus />
      <Heading heading="Task Details" />
      <div>
        <div className="container ">
          <div className="row d-flex justify-content-center">
            <div className="col-md-7">
              <div className="details-full p-3 py-4">
                <div className="text-center">
                  <img src={s2} width="150" className="rounded-circle" />
                </div>

                <div  className="text-center mt-3">
                  <h5 className="mt-2 mb-0 fonts14 fontWeight">
                  Rodent Control
                  </h5>
                  <div  className="col-12 d-flex flex-column mt-4">
                    {/* <div className="d-flex flex-row align-items-center ">
                      <h5 className="col-5 fonts12 textLeft">Task ID</h5>
                      <h5 className="col-2 fonts12 justify-content-center">-</h5>
                      <h5 className="col-5 fonts12 textLeft">{task?._id || 'Task ID Not Available'}</h5>
                    </div> */}
                    <div  className="d-flex flex-row align-items-center ">
                      <h5 style={{fontWeight:'bold'}} className="col-5 fonts12 textLeft">Customer Name</h5>
                      <h5 className="col-2 fonts12 justify-content-center">
                        -
                      </h5>
                      <h5  className="col-5 fonts12 textLeft">Test 1</h5>
                    </div>
                    <div className="d-flex flex-row align-items-center ">
                      <h5 style={{fontWeight:'bold'}} className="col-5 fonts12 textLeft">Email</h5>
                      <h5 className="col-2 fonts12 justify-content-center">
                        -
                      </h5>
                      <h5 className="col-5 fonts12 textLeft">
                        test1@gmail.com
                      </h5>
                    </div>
                    <div className="d-flex flex-row align-items-center ">
                      <h5 style={{fontWeight:'bold'}} className="col-5 fonts12 textLeft">Phone</h5>
                      <h5 className="col-2 fonts12 justify-content-center">
                        -
                      </h5>
                      <h5 className="col-5 fonts12 textLeft">
                        +973 9898787864
                      </h5>
                    </div>
                    <div className="d-flex flex-row align-items-center ">
                      <h5 style={{fontWeight:'bold'}} className="col-5 fonts12 textLeft">Start Date</h5>
                      <h5 className="col-2 fonts12 justify-content-center">
                        -
                      </h5>
                      <h5 className="col-5 fonts12 textLeft">20/02/2024</h5>
                    </div>
                    <div className="d-flex flex-row  align-items-center ">
                      <h5 style={{fontWeight:'bold'}} className="col-5 fonts12 textLeft">Status</h5>
                      <h5 className="col-2 fonts12 justify-content-center">
                        -
                      </h5>
                      <h5 className="col-5 fonts12 textLeft">Yet to start</h5>
                    </div>
                  </div>
                </div>
                <div className="mt-3" style={{ backgroundColor: " #8be97d1f" }}>
                  <h6 className="pt-2 fonts14 fontWeight">Task Description</h6>
                  <div className=" task-des mt-1">
                    <p className="fonts10 p-2">
                      Identification and sealing of entry points to prevent
                      future infestations. Strategic placement of bait stations
                      and traps for effective rodent removal. Regular monitoring
                      to ensure long-term rodent control.
                    </p>
                  </div>
                </div>

                <div>
                  <button
                    type="button"
                    style={{ fontSize: "13px" }}
                    onClick={handleAddTeamMemberClick}
                    class="btn btn-outline-secondary mt-3 "
                  >
                    Add Team Member
                  </button>
                </div>

                {showForm && (
                  <div className="d-flex justify-content-center mt-4">
                    <form
                      style={{
                        backgroundColor: "rgba(139, 233, 125, 0.12)",
                        borderRadius: "20px",
                      }}
                      className="col-10 d-flex justify-content-end"
                    >
                      <div className="container col-10 p-3">
                        <h6 className="mb-3">Add Technician</h6>
                        <input
                          id="addtechnician"
                          className="form-control mb-3"
                          placeholder="Enter Name"
                        />
                        {/* <button className="btn btn-primary fonts12 mt-3 mb-2" onClick={handleStart}>Add </button> */}
                      </div>
                    </form>
                  </div>
                )}

                <div className="buttons d-flex justify-content-center gap-3 mt-3">
                  <button
                    className="btn btn-primary fonts12"
                    onClick={handleStart}
                  >
                    Start Task{" "}
                  </button>
                  <button
                    type="button"
                    onClick={() => navigate("/home")}
                    class="btn btn-outline-secondary fonts12"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Model
        show={startTask}
        modalTitle="Start Task"
        modalContent="Are you sure you want to start the task ?"
        onClose={handleClose}
        onConfirm={handleScan}
      />
    </>
  );
};

export default TaskDetails;
