import React, { useState, useEffect } from "react";
import Model from "../../Reusable/Model";
import Menus from "../../Screens/Customer/Home/Menus/Menus";
import clock from "../../Assets/Images/icons8-clock-100.png";
import { useNavigate } from "react-router-dom";

const StartTask = () => {
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [isRunning, setIsRunning] = useState(true);

  let [stopTask, setStopTask] = useState(false);

  let navigate = useNavigate();

  let handleshow = () => {
    setStopTask(true);
  };

  let handleClose = () => {
    setStopTask(false);
  };
  let handlestop = () => {
    setIsRunning(false);
    setTime({ hours: 0, minutes: 0, seconds: 0 });
    navigate("/chemical/list");
  };

  useEffect(() => {
    let interval;
    if (isRunning) {
      interval = setInterval(() => {
        setTime((prevTime) => {
          const newSeconds = prevTime.seconds + 1;
          const newMinutes = prevTime.minutes + Math.floor(newSeconds / 60);
          const newHours = prevTime.hours + Math.floor(newMinutes / 60);

          return {
            hours: newHours,
            minutes: newMinutes % 60,
            seconds: newSeconds % 60,
          };
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isRunning]);

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  const pauseTimer = () => {
    setIsRunning(false);
  };

  const resumeTimer = () => {
    setIsRunning(true);
  };

  //   const stopTimer = () => {
  //     setIsRunning(false);
  //     setTime({ hours: 0, minutes: 0, seconds: 0 });
  //   };

  return (
    <div>
      <Menus />
      <div className="d-flex justify-content-center align-items-center flex-column mt-4">
        <div className="m-2 p-2" style={{
            backgroundColor: "rgb(159 221 90 / 20%)",
            // width: "300px",
            // height: "350px",
            borderRadius: "0px",
          }}>
        <p className="fonts12 mt-2">
          {" "}
          🕒 NOTE: Work has commenced and the timer is now running. Once the
          task is completed, kindly hit the stop button. Thank you!
        </p>
        </div>

        <div className="col-12 d-flex flex-column mt-4">
          <div style={{paddingLeft:'30px'}} className="d-flex flex-row align-items-center ">
            <h5
              style={{ fontWeight: "bold" }}
              className="col-5 fonts12 textLeft"
            >
              Customer Name
            </h5>
            <h5 className="col-2 fonts12 justify-content-center">-</h5>
            <h5 className="col-5 fonts12 textLeft">Test 1</h5>
          </div>
          <div style={{paddingLeft:'30px'}} className="d-flex flex-row align-items-center ">
            <h5
              style={{ fontWeight: "bold" }}
              className="col-5 fonts12 textLeft"
            >
              Service Name
            </h5>
            <h5 className="col-2 fonts12 justify-content-center">-</h5>
            <h5 className="col-5 fonts12 textLeft">Rodent Control</h5>
          </div>
        </div>


      </div>
      <div className="d-flex flex-column justify-content-center gap-4 align-items-center mt-4">
        <div
          className="bf d-flex flex-column justify-content-center align-items-center gap-3 "
          style={{
            backgroundColor: "rgb(159 221 90 / 42%)",
            width: "300px",
            height: "350px",
            borderRadius: "30px",
          }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center gap-2">
            <h2>Timer</h2>
            <img src={clock} alt="clock" />
          </div>
          <div>
            <h1>
              {formatTime(time.hours)}:{formatTime(time.minutes)}:
              {formatTime(time.seconds)}
            </h1>
          </div>
          <div className="d-flex gap-4 ">
            {isRunning ? (
              <>
                <button className="btn btn-danger" onClick={pauseTimer}>
                  Pause
                </button>
                <button className="btn btn-primary ml-2" onClick={handleshow}>
                  Stop
                </button>
              </>
            ) : (
              <button className="btn btn-success" onClick={resumeTimer}>
                Resume
              </button>
            )}
          </div>
        </div>
      </div>
      <Model
        show={stopTask}
        modalTitle="Stop your work ?"
        modalContent="Are you sure you want to Stop your work ?"
        onClose={handleClose}
        onConfirm={handlestop}
      />
    </div>
  );
};

export default StartTask;
