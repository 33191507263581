import React from 'react'
import Menus from '../Screens/Customer/Home/Menus/Menus'
import { Heading } from '../Reusable/Headings/Heading'
import Caroseuls from '../Reusable/Caroseuls'
// import OurServices from '../Screens/Customer/Services/OurServices/OurServices'
// import MyTask from './Task/MyTask'
import MyTaskList from './Task/MyTaskList'
const Home = () => {
  return (
    <div>
       <Menus title='HOME'/>
       <section>
        <Heading heading="Your Ultimate pest Control Solution start Here"  />
       </section>
       <section>
        <Caroseuls/>
       </section>
    <MyTaskList/>
       <section>

       </section>

    </div>
  )
}

export default Home
