import React from "react";
import SignatureCanvas from "react-signature-canvas";
import { useState, useEffect, useRef } from "react";
import Menus from "../../Screens/Customer/Home/Menus/Menus";
const SignnaturePage = () => {
  const sigRef = useRef();
  const [signature, setSignature] = useState(null);
  const [showSignaturePad, setShowSignaturePad] = useState(false);
  const handleSignatureEnd = () => {
    setSignature(sigRef.current.toDataURL());
  };
  const clearSignature = () => {
    sigRef.current.clear();
    setSignature(null);
  };

  useEffect(() => {
    console.log(signature);
  }, [signature]);

 


  const handleToggleChange = () => {
    setShowSignaturePad(!showSignaturePad);
  };


  return (
    <div>
      <Menus />
      <div>
        <div className="container">
          <div style={{ fontWeight: "bold" }}>
            <p >
              Service Acknowledgement
            </p>
          </div>
          <form>
            <div class="mb-3 col-10 d-flex flex-column p-3 ">
              <label
                for="exampleInputEmail1"
                class=" d-flex justify-content-start form-label mt-2"
              >
                Technician Name :     
              </label>
              <input
                type="text"
                className="col-5 form-control "
                placeholder="Enter Technician Name"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div class="mb-3 col-10 d-flex flex-column p-3  ">
              <label class=" d-flex justify-content-start form-label">
                Technician Signature :
              </label>
              <SignatureCanvas
                penColor="black"
                canvasProps={{ className: "signature m-0 " }}
                ref={sigRef}
                onEnd={handleSignatureEnd}
              />
              <div className="d-flex flex-row justify-content-start align-items-start m-2 ">
                <button type="button" onClick={clearSignature} class="btn btn-secondary">Clear</button>
              </div>
            </div>
          </form>

          <hr />
          <form>
      <div className="mb-3 col-10 d-flex flex-column p-3 ">
        <label
          htmlFor="exampleInputEmail1"
          className="d-flex justify-content-start form-label mt-2"
        >
          Customer Name:
        </label>
        <input
          type="text"
          className="col-5 form-control"
          placeholder="Enter Customer Name"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
        />
      </div>
      <div className="d-flex justify-content-start p-3">
        <span>Customer available:</span>
        <input
          type="checkbox"
          id="toggle"
          onChange={handleToggleChange}
        />
        <label className="label" htmlFor="toggle"></label>
      </div>

      {showSignaturePad && (
        <div className="mb-3 col-10 d-flex flex-column p-3">
          <label className="d-flex justify-content-start form-label">
            Customer Signature:
          </label>
          <SignatureCanvas
            penColor="black"
            canvasProps={{ className: "signature m-0" }}
            ref={sigRef}
            onEnd={handleSignatureEnd}
          />
         <div className="d-flex flex-row justify-content-start align-items-start m-2 ">
                <button type="button" onClick={clearSignature} class="btn btn-secondary">Clear</button>
              </div>
        </div>
      )}

      <button type="submit" className="btn btn-primary m-4">
        Submit
      </button>
    </form>
        </div>
      </div>
    </div>
  );
};

export default SignnaturePage;
