import React from 'react'
import Menus from '../../../Home/Menus/Menus'
import { Heading } from '../../../../../Reusable/Headings/Heading'
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import jsPDF from 'jspdf';

import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';


const SpecificHistory = () => {
    const navigate = useNavigate();
    const onFinish = () => {
        navigate('/home');
    };

    const specificHistoryLabel = [
        {
            label: "Service Id",
            formData: "908",
            // padding: "30px 20px 20px 20px",
            index: 1
        },
        {
            label: "Service Name",
            formData: "Crawling",
            // padding: "0px 20px 20px 20px",
            index: 2
        },
        {
            label: "Date",
            formData: "25-01-2024",
            // padding: "0px 20px 20px 20px",
            index: 3
        },
        {
            label: "Start Time",
            formData: "09:19",
            // padding: "0px 20px 20px 20px",
            index: 4
        },
        {
            label: "Technician Name",
            formData: "XYZ",
            // padding: "0px 20px 20px 20px",
            index: 5
        },
        {
            label: "Status",
            formData: "Completed",
            // padding: "0px 20px 20px 20px",
            index: 5
        },
    ]

    const downloadPdf = () => {
        const doc = new jsPDF();

        // Add title
        doc.text("Service History", 10, 10);

        // Add data
        specificHistoryLabel.forEach((data, index) => {
            doc.text(`${data.label}: ${data.formData}`, 10, 20 + index * 10);
        });

        // Save the PDF
        doc.save('specific_history.pdf');
    };

    const downloadCSV = () => {
        const csvData = specificHistoryLabel.map(item => Object.values(item).join(',')).join('\n');
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', 'specific_history.csv');
        link.click();
    };


    
    return (
        <>
        <div className='history-full'>
            <Menus title="Crawling Insects" />
            <div className='d-flex flex-row'>
                <div className='col-2'>
                    <IoIosArrowBack className='backArrow' onClick={onFinish} />
                </div>
                <div className='col-8 d-flex justify-content-center'>
                    <Heading heading="Service History" />
                </div>
              

            </div>
            <div id='body'> 
            <DropdownButton  id="dropdown-basic-button" title="Download">
      <Dropdown.Item style={{backgroundColor:'rgb(143,200,79)'}}  href="#/action-1" onClick={downloadPdf}>Pdf</Dropdown.Item>
      <Dropdown.Item style={{backgroundColor:'rgb(143,200,79)'}} href="#/action-2" onClick={downloadCSV}>Excel</Dropdown.Item>
      
    </DropdownButton>
  	</div>
  <div/>
            <div className='padding'>
                <div className='allServicesHistory card'>
                    {specificHistoryLabel.map((data) => {
                        return (
                            <div className='d-flex flex-row align-items-center' style={{ padding: "10px 20px 20px 20px", }}>
                                <div className='col-6 d-flex align-items-center'>
                                    <h6 style={{fontSize:"14px"}} className='allHistTitle'>{data.label} </h6>
                                </div>
                                <div className='col-2'> : </div>
                                <div className='col-5 d-flex justify-content-start align-items-center'>
                                    <text style={{fontSize:"14px"}}  className='allHistText'>{data.formData}</text>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
           

        </div>

       


        </>
        

        
    )
}

export default SpecificHistory