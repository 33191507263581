import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../../../../Assets/CSS/CustomerCss/Home/Home.css";
import pestlogo from "../../../../Assets/Images/Pest P.png";
// import Nav from 'react-bootstrap/Nav';
// import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from "react-bootstrap/NavDropdown";

const Menus = () => {
  const Email1 = "technician@gmail.com";
  const Email2 = 'customer@gmail.com';
  const storedemail = sessionStorage.getItem("email");

  // console.log("storedemail========>",storedemail)

  return (
    <>
      {Email1 === storedemail &&
        ["sm"].map((expand) => (
          <Navbar key={expand} expand={expand} className="mb-3 homeNav">
            <Container fluid>
              <Navbar.Brand href="#">
                <img className="mainlogo" src={pestlogo} alt="pest logo" />
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    Technician
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3 menus">
                    <Nav.Link href="/tech/home">Home</Nav.Link>
                    <NavDropdown title="My Tasks" id="basic-nav-dropdown">
                      <NavDropdown.Item href="#action/3.1">
                        Start Task
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.2">
                        Ongoing Tasks
                      </NavDropdown.Item>
                      <NavDropdown.Item href="#action/3.3">
                        Completed Tasks
                      </NavDropdown.Item>
                    </NavDropdown>
                    <Nav.Link href="/profile">My Account</Nav.Link>
                    <Nav.Link href="/">Log out</Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}

      {Email2 === storedemail &&
        ["sm"].map((expand) => (
          <Navbar key={expand} expand={expand} className="mb-3 homeNav">
            <Container fluid>
              <Navbar.Brand href="#">
                <img className="mainlogo" src={pestlogo} alt="pest logo" />
              </Navbar.Brand>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                    Customer
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3 menus">
                    <Nav.Link href="/home">Home</Nav.Link>
                    <Nav.Link href="#action2">Our Services</Nav.Link>
                    <Nav.Link href="#action2">Service History</Nav.Link>
                    <Nav.Link href="#action2">My Account</Nav.Link>
                    <Nav.Link href="#action2">Log out</Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
    </>
  );
};

export default Menus;
