import React, { useRef, useState, useEffect } from "react";
import "../../Assets/CSS/Login/Login.css"
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input } from 'antd';
import pestlogo from "../../Assets/Images/pestlogo.png"
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

const Signin = () => {
    const [email, setEmail] = useState('')
    const formRef = useRef(null);
    const navigate = useNavigate();

    const onFinish = (e) => {
        const Email1 = 'technician@gmail.com';
        const Email2 = 'customer@gmail.com';

        // if (email) {
        //     sessionStorage.setItem('email', email); 
        //     navigate('/tech/home');
        //     toast.success('Login successful');
        // } else {
        //    navigate('/home')
        // }
        if(email === Email1){
                 sessionStorage.setItem('email', email); 
            toast.success('Login successful');
            navigate('/tech/home');
        }else if(email === Email2){
            toast.success('Login successful');
            navigate('/home');
        }else{
            toast.error('Login failed');
        }
    }

    return (
        <section className="login-block">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="auth-box">
                            <div className="card-block login-form-box checkout-page-style">
                                <div className='col-12 d-flex justify-content-center '>
                                    <img src={pestlogo} className='img-fluid LoginLogo mb-5' />
                                </div>
                                <Form
                                    name="normal_login"
                                    className="col-12 login-form"
                                    initialValues={{
                                        remember: true,
                                    }}
                                    onFinish={onFinish}
                                >
                                    <Form.Item
                                        className='loginInput'
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Email!',
                                            },
                                        ]}
                                        onChange={(e) => { setEmail(e.target.value); }}
                                    >
                                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Password!',
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            prefix={<LockOutlined className="site-form-item-icon" />}
                                            type="password"
                                            placeholder="Password" />
                                    </Form.Item>
                                    <Form.Item>
                                        <Form.Item name="remember" valuePropName="checked" noStyle>
                                            <Checkbox>Remember me</Checkbox>
                                        </Form.Item>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" className="col-12 login-form-button">
                                            Log in
                                        </Button>
                                    </Form.Item>
                                    <Form.Item>
                                        <div className="login-form-forgot" >
                                            Forgot password?
                                        </div>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </section>
    );
};

export default Signin;