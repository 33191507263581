import React from "react";
import { SubHeading } from "../../../../Reusable/Headings/Heading";
import "../../../../Assets/CSS/CustomerCss/Services/Services.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ServiceHistory = () => {
  const serviceHistory = [
    {
      title: "Crawling Insects",
      date: "24-01-2024",
      status: "Completed",
      index: 1,
    },
    {
      title: "Bird Control",
      date: "27-01-2024",
      status: "Completed",
      index: 2,
    },
  ];

  const navigate = useNavigate();
  const onFinish = () => {
    navigate("/specificHistory");
  };

  const onhandleClick = () => {
    navigate("/allservicesHistory");
  };
  return (
    <div className="padding">
      <div>
        <SubHeading
          subHeading="Service History"
          subInsideHeading="ViewAll"
          onClick={onhandleClick}
        />
      </div>
      <div>
        {serviceHistory.map((data) => {
          return (
            <div className="card d-flex flex-row serviceHistoryCard mt-3">
              <div className="col-10 mt-2 px-3 p-1">
                <h6 className="serviceHistorytitle  ">{data.title}</h6>
                <div className="d-flex justify-content-start mt-3 mb-2">
                  <FaRegCalendarAlt className="calendarIcon" />
                  <div className="ServiceHistoryDate px-2">{data.date}</div>
                  <div className="ServiceHistoryStatus px-2">{data.status}</div>
                </div>
              </div>
              <div className="col-2 d-flex align-items-center justify-content-center">
                <div onClick={onFinish} style={{fontSize:"12px"}}>View</div>                             
              </div>             
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ServiceHistory;
