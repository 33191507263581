import React, { useEffect, useState } from 'react';
import { SubHeading } from "../../Reusable/Headings/Heading";
import Menus from '../../Screens/Customer/Home/Menus/Menus';
import { Heading } from '../../Reusable/Headings/Heading';
import s1 from '../../Assets/Images/s1.webp'
import s2 from '../../Assets/Images/s2.webp'
import s3 from '../../Assets/Images/s3.webp';
import s4 from '../../Assets/Images/s4.webp'
import s5 from '../../Assets/Images/s5.webp'
import s6 from '../../Assets/Images/s6.webp'
import s7 from '../../Assets/Images/s7.webp'
import s8 from '../../Assets/Images/s8.webp'
import s9 from '../../Assets/Images/s9.webp'

import { useNavigate, useLocation } from 'react-router-dom';
// import ApiService from '../../Services/TaskServices';

const Tasdetails = [
  { id:1, serviceName:"Rodent Control", companyName:'Test 1',img:s2 },
  { id:2, serviceName:"Flying Insects Control", companyName:'Test 2',img:s3 },
  { id:3, serviceName:"Bed Bugs Control", companyName:'Test 3',img:s7 },
  { id:4, serviceName:"Termite Control", companyName:'Test 4',img:s6 },
  { id:5, serviceName:"Crawling Insects Control", companyName:'Test 5',img:s1}, 
  { id:6, serviceName:"Bird Control", companyName:'Test 6',img:s4 }, 
  { id:7, serviceName:"Vector Control", companyName:'Test 7',img:s8 }, 
  { id:8, serviceName:"Snake and Scroption Control", companyName:'Test 8',img:s5 }, 
  { id:9, serviceName:"Feral Cat Control", companyName:'Test 9',img:s9 },


]

const MyTaskList = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  

  useEffect(() => {
    if (location.pathname === '/tech/home') {
            setData(Tasdetails.slice(0, 3)); // Display only the first two tasks
          } else {
            setData(Tasdetails);
          }
    // getAllTasks();
  }, []);

  // const getAllTasks = async () => {
  //   try {
  //     const AllTasks = await ApiService.TaskList();
  //     const Tasks = AllTasks.data.Results;
  //     if (location.pathname === '/tech/home') {
  //       setData(Tasks.slice(0, 2)); // Display only the first two tasks
  //     } else {
  //       setData(Tasks);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching tasks:', error);
  //   }
  // };

  // const handleViewDetails = (task) => {
  //   navigate(`/taskdetails/${task._id}`, { state: { task } });
  // };
  const handleViewDetails = ()=>{
    navigate('/taskdetails')
  }

  return (
    <>
      <div className="tech-full">
        {location.pathname !== '/tech/home' && <Menus />}
        <div className="container">
          {location.pathname === '/tech/home' && <SubHeading subHeading="My Tasks" subInsideHeading="View All" />}
          {location.pathname !== '/tech/home' && <Heading heading="Task List" />}
          {data.map((task) => (
            <div className="card mb-3 mt-3 d-flex flex-row align-items-center p-2" key={task.id}>
              <div className="col-2 d-flex justify-content-center">
                <img className="serviceImage rounded" src={task.img} alt="Product" />
              </div>
              <div className="col-8 d-md-flex flex-md-column justify-content-start px-3">
                <h5 className="fonts13 fontWeight textLeft">{task?.serviceName || 'Service Name Not Available'}</h5>
                <h6 className="fonts textLeft">Customer : {task?.companyName || 'Company Name Not Available'}</h6>
              </div>
              <div className="col-2 d-flex justify-content-center">
                <button
                  onClick={handleViewDetails}
                  className="btn btn-primary btn-sm"
                  style={{ fontSize: '9px' }}
                  type="button"
                >
                  View
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MyTaskList;
