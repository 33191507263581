import React, { useState } from "react";
import QrScanner from "react-qr-scanner";
import Menus from "../../Screens/Customer/Home/Menus/Menus";
import success from '../../Assets/Images/success.svg'
import { useNavigate } from "react-router-dom";
import QrFrame from "../../Assets/Images/qr-frame.svg";


export default function App() {
  const [result, setResult] = useState(null); // Initialize as null
  let navigate = useNavigate()

  const handleError = (error) => {
    console.error(error);
  };

  const handleScan = (data) => {
    if (data) {
      setResult(data); // Store the result object
    }
  };

  const handleScanAgain = () => {
    setResult(null); // Reset result to null to trigger scanning again
  };

  return (
    <>
    <Menus/>
    {!result  && (
    <div className="mb-5">
        <h3>Scan the QRCode</h3>
      </div>)}
        <div style={{ position: "relative" }}>
      {!result && ( // Render the scanner if result is null
        <>
          <QrScanner
            delay={1} // Reduced delay for faster scanning
            onError={handleError}
            onScan={handleScan}
            style={{ width: "100%" }}
            facingMode="environment" // Specify to use the back camera
          />
          <div style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 1 }}>
          <img
            src={QrFrame}
            alt="Qr Frame"
            width={220}
            height={256}
            className="qr-frame"
          />
          </div>
        </>
      )}
      {result && ( // Render the result if result is not null
        <div>

<div className="b m-5 d-flex justify-content-center flex-column  align-items-center " >
           
            <img style={{width:'220px'}} className="m-3" src={success} alt="success" />
            <p className="m-2 fs-5"> Successfully Scanned </p>
            <hr style={{width:'100%'}}/>
            <h3 className="m-2">Scanned Result</h3>
            <div style={{ width:'300px'  }} className="bt m-3 ">
              <h6>{result.text}
              </h6>
            </div>
            <div className="d-flex gap-5 mb-5 mt-4">
            <button className="btn btn-primary  " onClick={()=>navigate('/start/task')}>
start Task
            </button>

            <button
            type="button"
            
            class="btn btn-outline-secondary"
          >
            Cancel
          </button>
            </div>
          </div>



          {/* <p>Scanned QR Code: {result.text}</p>  */}
          <button onClick={handleScanAgain}>Scan Again</button>
        </div>
      )}
    </div>
    </>
  );
}















// import React, { useEffect, useRef, useState } from "react";
// import Menus from "../../Screens/Customer/Home/Menus/Menus";
// import QrScanner from "qr-scanner";
// import QrFrame from "../../Assets/Images/qr-frame.svg";
// import success from '../../Assets/Images/success.svg'
// import { useNavigate } from "react-router-dom";

// const ScanQrCode = () => {
//   // QR States
//   const scanner = useRef(null);
//   const videoEl = useRef(null);
//   const qrBoxEl = useRef(null);
//   const [qrOn, setQrOn] = useState(true);
//   const [scannedResult, setScannedResult] = useState(undefined);

//   let navigate = useNavigate()

//   useEffect(() => {
//     if (videoEl.current && !scanner.current) {
//       scanner.current = new QrScanner(videoEl.current, onScanSuccess, {
//         onDecodeError: onScanFail,
//         preferredCamera: "environment",
//         highlightScanRegion: true,
//         highlightCodeOutline: true,
//         overlay: qrBoxEl.current || undefined,
//       });

//       scanner.current
//         .start()
//         .then(() => setQrOn(true))
//         .catch((err) => {
//           if (err) setQrOn(false);
//         });
//     }

//     return () => {
//       if (!videoEl.current) {
//         scanner.current?.stop();
//       }
//     };
//   }, []);

//   const onScanSuccess = (result) => {
//     console.log(result);
//     setScannedResult(result?.data);
//     scanner.current?.stop();
//   };

//   const onScanFail = (err) => {
//     console.log(err);
//   };

//   useEffect(() => {
//     if (!qrOn) {
//       alert(
//         "Camera is blocked or not accessible. Please allow camera in your browser permissions and reload."
//       );
//     }
//   }, [qrOn]);

//   return (
//     <>
//       <Menus />
//       {!scannedResult  && (<div>
//         <h3>Scan the QRCode</h3>
//       </div>)}
//       <div className="qr-reader">
//         {!scannedResult && (
//           <video ref={videoEl} style={{ display: scannedResult ? "none" : "block" }}></video>
//         )}
//         <div
//           ref={qrBoxEl}
//           className="qr-box"
//           style={{ display: scannedResult ? "none" : "block" }}
//         >
//           <img
//             src={QrFrame}
//             alt="Qr Frame"
//             width={256}
//             height={256}
//             className="qr-frame"
//           />
//         </div>

//         {scannedResult && (
//           <div className="b m-5 d-flex justify-content-center flex-column  align-items-center " >
           
//             <img style={{width:'220px'}} className="m-3" src={success} alt="success" />
//             <p className="m-2 fs-5"> Successfully Scanned </p>
//             <hr style={{width:'100%'}}/>
//             <h3 className="m-2">Scanned Result</h3>
//             <div style={{ width:'300px'  }} className="bt m-3 ">
//               <h6>{scannedResult}
//               </h6>
//             </div>
//             <div className="d-flex gap-5 mb-5 mt-4">
//             <button className="btn btn-primary  " onClick={()=>navigate('/start/task')}>
// start Task
//             </button>

//             <button
//             type="button"
            
//             class="btn btn-outline-secondary"
//           >
//             Cancel
//           </button>
//             </div>
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default ScanQrCode;
