import React,{useState} from 'react'
import Menus from '../../Screens/Customer/Home/Menus/Menus'
import { Heading } from '../../Reusable/Headings/Heading'
import { useNavigate } from 'react-router-dom';

import { Divider, Radio, Table } from 'antd';
const columns = [
  {
    title: 'Chemicals ',
    dataIndex: 'chemical',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    render:()=> <input style={{width:'100px'}} placeholder='Enter Qty' className='  form-control' />
  },
  
];
const data = [
  {
    key: '1',
    chemical: 'Boric acid',
    quantity:' ',
   
   
  },
  {
    key: '2',
    chemical: 'Hydramethylnon',
    quantity: '',
   
  },
  {
    key: '3',
    chemical: 'Permethrin',
    quantity: '',
   
  },
  {
    key: '4',
    chemical: 'Cyfluthrin',
    quantity: '',
   
  },
  {
    key: '5',
    chemical: 'Bifenthrin',
    quantity: '',
   
  },
  
];

// rowSelection object indicates the need for row selection
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
 
};

const Chemicals = () => {
    const [selectionType, setSelectionType] = useState('checkbox');
    let navigate = useNavigate()
  return (
    <div>
      <Menus/>
   {/* <Heading/> */}
   <div className='m-2 mb-0 p-1' style={{
            backgroundColor: "rgb(159 221 90 / 20%)",
            // width: "300px",
            // height: "350px",
            borderRadius: "0px",
          }}>
        <p className="fonts12 mt-2">
          {" "}
          
If any chemicals were utilized during the task, please specify from the following list
        </p>
        </div>

   <div className='container mt-0' >
      <Radio.Group
        onChange={({ target: { value } }) => {
          setSelectionType(value);
        }}
        value={selectionType}
      >
      </Radio.Group>

      <Divider />

      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={data}
      />
    </div>

    <button type="submit" onClick={()=>navigate('/signature/page')} className="btn btn-primary m-4">
        Submit
      </button>
    </div>
  )
}

export default Chemicals
